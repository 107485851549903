<template>
  <div class="section gauge">
    <p class="mb-2 gauge-p" v-scroll-reveal>
      Your Growth Score is designed to show you where you currently see the
      highest performance and help you understand where to focus your efforts to
      drive the most growth for your firm. Your Growth Score and a simple
      results report have been emailed to your growth officer.
    </p>

    <div class="container mt-4">
      <div class="row pt-4">
        <div class="col-lg-3 text-start">
          <div v-scroll-reveal class="gauge-title">
            {{ title }}
          </div>
          <div v-scroll-reveal class="gauge-description">
            {{ description }}
          </div>

          <a :href="pdfUrl" target="_blank" class="btn btn-primary small mt-4"
            >Save your results
          </a>
        </div>
        <div class="col-lg-9">
          <div class="gauge-outer ps-4">
            <div class="gauge-score" :style="scoreStyles">
              YOUR SCORE
              <span>{{ parseInt(score) }}</span>
              <div class="triangle blue"></div>
            </div>
            <div class="gauge-inner">
              <div class="colors row">
                <div
                  class="color col text-start"
                  v-for="(color, i) in colors"
                  :key="color.hex"
                  :style="{ background: color.hex }"
                >
                  <span v-if="i > 0">{{ i * 10 }}</span>
                </div>
              </div>
            </div>
            <div
              class="gauge-avg"
              :style="{
                marginLeft: 'calc(' + GET_AVERAGES.total + '% - 100px)',
              }"
            >
              <div class="range"></div>
              <div class="avg-text">Fastest growing advisors range</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Gauge",
  props: {
    score: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      description: "",
      colors: [
        {
          hex: "#cc5c5b",
        },
        {
          hex: "#d77d7e",
        },
        {
          hex: "#e29e9d",
        },
        {
          hex: "#ecbfbe",
        },
        {
          hex: "#f7cf8e",
        },
        {
          hex: "#fadcac",
        },
        {
          hex: "#fbe7c7",
        },
        {
          hex: "#b1f0ce",
        },
        {
          hex: "#96eabe",
        },
        {
          hex: "#77e4ac",
        },
      ],
    };
  },
  beforeCreate() {
    window.mobileCheck = function () {
      return window.innerWidth <= 800 && window.innerHeight <= 600;
    };
  },
  computed: {
    ...mapGetters(["GET_AVERAGES", "GET_QUESTIONAIRE", "API_URL"]),
    user() {
      return this.$store.state.user;
    },
    pdfUrl() {
      return this.API_URL.replace(
        "/api/",
        "/pdf/pdf?r=" + this.GET_QUESTIONAIRE
      );
    },
    scoreStyles() {
      // calc(0% - 20px)
      // calc(100% - 50px)
      let pixPer = 30 * (this.score / 100) + 20;
      return {
        marginLeft: `calc(${this.score}% - ${pixPer}px)`,
      };
    },
  },
  mounted() {
    if (this.score.toFixed(0) > 79) {
      (this.title = "Optimized"),
        (this.description =
          "Great work. Looks like you have automated your business building strategies across many categories. Check your opportunities below to learn some helpful ways to organically grow your practice.");
    } else if (this.score.toFixed(0) > 49) {
      (this.title = "Active"),
        (this.description =
          "Great work. Looks like you have automated your business building strategies across many categories. Check your opportunities below to learn some helpful ways to organically grow your practice.");
    } else {
      (this.title = "Visible"),
        (this.description =
          "Great work. Looks like you have automated your business building strategies across many categories. Check your opportunities below to learn some helpful ways to organically grow your practice.");
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.gauge {
  padding-top: 0px !important;

  .container {
    width: 80%;
    // margin-top: 52px;

    @media (max-width: 991.98px) {
      width: 90%;
    }

    .col-lg-9 {
      @media (max-width: 991.98px) {
        padding: 0px !important;
      }
    }

    .gauge-outer {
      .gauge-score {
        font-weight: bold;
        width: 70px;
        text-align: center;
        margin-bottom: -79px;
        font-size: 16px;
        // z-index: 1000;
        z-index: 1;
        position: relative;

        span {
          font-size: 48px;
        }

        .triangle {
          margin-top: 0px !important;
        }
      }

      .gauge-avg {
        width: 200px;
        text-transform: uppercase;
        font-weight: bold;
        z-index: 999;
        position: relative;

        .range {
          width: 80px;
          margin-left: 60px;
          height: 124px;
          background-color: rgba(142, 142, 142, 0.4);
          border: 2px #00000033 solid;
          border-radius: 3px;
          margin-top: -106px;
          margin-bottom: 12px;
        }
      }

      .gauge-inner {
        border: 5px solid #272178;
        border-radius: 12px;
        overflow: hidden;

        .colors {
          .color {
            height: 79px;
            font-size: 13px;
            padding-top: 56px;

            span {
              border-left: 2px solid #11111177;
              padding: 7px 0 7px 12px;
              margin-left: -13px;
            }
          }
        }
      }

      @media (max-width: 991.98px) {
        padding-top: 34px;
        padding-left: 0 !important;

        .gauge-inner {
          .colors {
            .color {
              padding: 56px 0 0;

              span {
                margin-left: 0;
                font-size: 11px;
                padding-left: 3px;
              }
            }
          }
        }
      }
    }

    .gauge-number {
      color: $primary-color;
      font-family: "FinancierDisplay";
      font-size: 180px;

      @media (max-width: 991.98px) {
        font-size: 70px;
        padding-top: 20px;
      }
    }

    .gauge-title {
      color: $primary-color;
      font-family: "FinancierDisplay";
      font-size: 52px;
      text-transform: capitalize;

      @media (max-width: 991.98px) {
        font-size: 40px;
      }
    }

    .gauge-description {
      max-width: 600px;
      margin: 7px auto;
    }
  }

  p {
    max-width: 1024px;
    margin: 16px auto 0;

    &.gauge-p {
      font-size: 1.1rem;
      line-height: 1.3rem;
      margin-bottom: 10px;

      @media (max-width: 991.98px) {
        width: 90%;
        text-align: center;
        font-size: 16px;
      }
    }
    &.negative-bottom {
      margin-bottom: -100px;
    }
  }
}
.hidden {
  display: none;
}
</style>
