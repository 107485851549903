<template>
  <div class="container">
    <div class="full-row">
      <div class="content">
        <div class="header-row">
          <h2>Focus on Closing</h2>
          <p>
            To improve your prospect to client conversion, we’ll review best
            practices with CRM, how to enhance prospect and client experience,
            optimize nurture opportunities and more.
          </p>
        </div>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row purple">
      <div class="content">
        <h3>Business Recommendations</h3>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row">
      <div class="content main-content">
        <p>In this area we’ll cover:</p>
        <ul>
          <li>
            Sales Best Practices – A mature financial planning business includes
            an effective sales process that leads prospective clients through
            the sales journey and helps you turn prospects into clients. In this
            guide, you’ll learn about why it’s important to have a strong sales
            process and what makes up a thorough sales process so you can focus
            on helping your clients achieve their goals.
          </li>
          <li>
            Email and Email Nurture - Staying in regular contact with your
            prospects and clients through email can help your business grow and
            build trusting relationships with prospective and existing clients.
            In this guide, you'll learn about the importance of sending
            personalized and relevant emails, how you can use client feedback
            and find more convenient ways to send tailored messages while
            keeping up with your regular tasks.
          </li>
          <li>
            Events – Online and in person - Hosting events gives prospective and
            existing clients the opportunity to get to know your company and
            relate to your brand through personalized teaching and messaging. In
            this guide, you'll learn about the importance of hosting events, how
            you should prepare for them and the necessary steps to take after
            the event to optimize their effect.
          </li>
          <li>
            Client Experience – Providing the best client experience at all
            phases of the buyer journey is essential for sustaining growth in
            your business. It both helps retain existing clients and attracts
            new prospects. In this guide, you'll learn why it's essential to
            provide a positive client experience and how doing so can help your
            clients become advocates for your firm.
          </li>
        </ul>
      </div>
      <div class="blank"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.full-row {
  display: flex;
  flex-direction: row;

  &.purple {
    background-color: #37176d;
    color: white;

    .content {
      padding: 0.5rem;
    }

    h3 {
      color: white !important;
      text-align: left;
      padding-left: 2rem;
      padding-bottom: 0;
      font-weight: 100;
    }
  }

  .content {
    width: 74%;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    .header-row {
      display: flex;
      flex-direction: column;

      h2 {
        margin-left: 1rem !important;
      }

      p {
        padding: 1rem;
        text-align: left;
      }
    }

    &.main-content {
      padding: 2rem;
      padding-top: 3rem;
      text-align: left;

      @media (max-width: 991.98px) {
        padding: 3rem 0.3rem 2rem;
      }

      ul {
        margin: 1rem;
        text-align: left;

        li {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .blank {
    width: 26%;

    @media (max-width: 991.98px) {
      width: 0;
    }
  }
}
</style>
