import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const SET_USER = "SET_USER";
const SAVE_ANSWER = "SAVE_ANSWER";
const SET_ANSWERED = "SET_ANSWERED";
const SAVE_AVERAGES = "SAVE_AVERAGES";
const SAVE_QUESTIONS = "SAVE_QUESTIONS";
const SET_QUESTIONAIRE = "SET_QUESTIONAIRE";
const SET_ACCORDIONCONTENT = "SET_ACCORDIONCONTENT";
const SET_FULL_RESPONSE = "SET_FULL_RESPONSE";

export default new Vuex.Store({
  state: {
    // questions: JSON.parse(window.localStorage.getItem("questions")) || [],
    user: JSON.parse(window.localStorage.getItem("user")) || [],
    questions: [],
    averages: JSON.parse(window.localStorage.getItem("averages")) || [],
    answered: JSON.parse(window.localStorage.getItem("answered")) || false,
    answers: JSON.parse(window.localStorage.getItem("answers")) || [],
    questionaire: window.localStorage.getItem("questionaire") || null,
    full_response: window.localStorage.getItem("full_response") || {},
    accordionContent: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      window.localStorage.setItem("user", JSON.stringify(state.user));
    },
    SAVE_ANSWER(state, answer) {
      state.answers[answer.key] = answer.value;
      window.localStorage.setItem("answers", JSON.stringify(state.answers));
    },
    SET_ANSWERED(state, answered) {
      state.answered = answered;
      window.localStorage.setItem("answered", JSON.stringify(state.answered));
    },
    SAVE_AVERAGES(state, averages) {
      state.averages = averages;
      window.localStorage.setItem("averages", JSON.stringify(state.averages));
    },
    SAVE_QUESTIONS(state, questions) {
      state.questions = questions;
      window.localStorage.setItem("questions", JSON.stringify(state.questions));
    },
    SET_QUESTIONAIRE(state, questionaire) {
      state.questionaire = questionaire;
      window.localStorage.setItem("questionaire", state.questionaire);
    },
    SET_ACCORDIONCONTENT(state, accordionContent) {
      state.accordionContent = accordionContent;
    },
    SET_FULL_RESPONSE(state, full_response) {
      state.full_response = full_response;
    },
  },
  actions: {
    async setUser({ commit, getters }, user) {
      let response;

      try {
        response = await axios.post(getters.API_URL + "users", user);
      } catch (e) {
        return { error: e };
      }

      commit(SET_USER, user);

      return response.data.user
        ? { user: response.data.user, data: response.data }
        : { error: response.data };
    },
    setFullResponse({ commit }, full_response) {
      commit(SET_FULL_RESPONSE, full_response);
    },
    setQuestionaire({ commit }, questionaire) {
      commit(SET_QUESTIONAIRE, questionaire);
    },
    saveAnswer({ commit }, answer) {
      commit(SAVE_ANSWER, answer);
    },
    setAnswered({ commit }, answered) {
      commit(SET_ANSWERED, answered);
    },
    saveAverages({ commit }, averages) {
      commit(SAVE_AVERAGES, averages);
    },
    saveQuestions({ commit }, questions) {
      commit(SAVE_QUESTIONS, questions);
    },
    toggleAccordionContent({ commit }, val) {
      commit(SET_ACCORDIONCONTENT, val);
    },
  },
  modules: {},
  getters: {
    API_URL: () => process.env.VUE_APP_API_URL,
    GET_USER: (state) => state.user,
    GET_ANSWERS: (state) => state.answers,
    IS_ANSWERED: (state) => state.answered,
    GET_AVERAGES: (state) => state.averages,
    GET_QUESTIONS: (state) => state.questions,
    GET_QUESTIONAIRE: (state) => state.questionaire,
    GET_FULL_RESPONSE: (state) => state.full_response,
  },
});
