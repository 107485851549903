<template>
  <section class="container w-full flex-lg flex-col-lg bg-purple cta">
    <h2 class="text-left" v-scroll-reveal>
      Next Steps: Connect with Your Growth Officer
    </h2>
    <div class="flex flex-lg flex-row">
      <article class="w-6-10">
        <p class="text-left">
          Your growth officer will receive a copy of your assessment results and
          will be reaching out to you soon. Feel free to reach out directly to
          them at your convenience.
        </p>
        <p class="text-left">
          If you would like to save a copy of your results, please click the
          &quot;Save your Results&quot; button below to save a PDF to your
          computer or reach out to your growth officer for a copy.
        </p>
        <div class="text-left">
          <a :href="pdfUrl" class="btn btn-primary small" target="_blank">
            Save Your Results
          </a>
        </div>
      </article>
      <article class="w-4-10">
        <img src="../assets/bicycle.svg" />
      </article>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      docs: [],
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["API_URL", "GET_QUESTIONAIRE"]),
    pdfUrl() {
      return this.API_URL.replace(
        "/api/",
        "/pdf/pdf?r=" + this.GET_QUESTIONAIRE
      );
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.cta {
  padding: 3rem;
  &.w-full {
    width: 100%;
  }

  &.flex,
  .flex {
    display: flex;
  }

  &.flex-col,
  .flex-col-lg {
    flex-direction: column;
  }

  &.flex-row,
  .flex-row {
    flex-direction: row;
  }

  &.bg-purple {
    background-color: #37176d;
  }

  .w-6-10 {
    width: 60%;
    display: inline-block;

    @media (max-width: 991.98px) {
      width: 90%;
      margin: 7px auto;

      p {
        text-align: center !important;
      }
    }
  }
  .w-4-10 {
    width: 40%;
    display: inline-block;
    vertical-align: top;

    img {
      max-width: 100%;
    }

    @media (max-width: 991.98px) {
      width: 90%;
      margin: 7px auto;

      p {
        text-align: center !important;
      }

      img {
        margin-top: 10px;
        max-width: 100%;
      }
    }
  }

  h2,
  p {
    color: white !important;
  }

  h2 {
    margin: inherit;
    margin-bottom: 2rem !important;
  }

  .text-left {
    text-align: left;
  }
  .btn-primary {
    border-radius: 2px !important;
  }
}
</style>
