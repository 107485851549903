<template>
  <div class="question-page">
    <Question :questionNumber="number" v-if="number" />
  </div>
</template>

<script>
import Question from "@/components/Question.vue";

export default {
  components: {
    Question,
  },
  data() {
    return {
      number: null,
    };
  },
  metaInfo() {
    return {
      title: `Question ${this.number}`,
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.number = parseInt(route.params.number);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
