<template>
  <header v-scroll-reveal class="small-header text-start py-2 px-3">
    <h1 :class="{ 'text-logo': !showLogo }">
      <img class="logo" src="../assets/g360-logo.png" v-if="showLogo" />
      <span v-else>Growth<strong>360°</strong></span>
      <span>&nbsp;Assessment</span>
    </h1>
  </header>
</template>

<script>
export default {
  name: "SmallHeader",
  props: {
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.small-header {
  background-color: #f2f2f2;
  border-bottom: 8px solid #3a0a58;

  h1 {
    color: $primary-color;
    padding-top: 7px;
    font-size: 36px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Asap Medium", Arial !important;

    &.text-logo {
      span {
        font-size: 48px !important;

        @media (max-width: 991.98px) {
          font-size: 24px !important;
        }
      }
    }

    strong {
      font-family: "Asap Bold", Arial !important;
    }

    span {
      display: block;
      font-size: 60px !important;
    }
  }

  .logo {
    width: 200px;
    height: 40px;
  }
}
</style>
