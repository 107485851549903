<template>
  <section class="container w-full flex-lg flex-col-lg cta">
    <div class="top-performing-list-item-button col-auto tt multi-button-row">
      <a
        v-on:click="navToChangeAnswers"
        href="/"
        class="btn btn-primary small"
        v-scroll-reveal
      >
        Change Answers
      </a>
      <a :href="pdfUrl" class="btn btn-primary small" target="_blank"
        >Save Your Results</a
      >
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      docs: [],
    };
  },
  methods: {
    navToChangeAnswers() {
      window.localStorage.setItem("answers", JSON.stringify([]));
    },
  },
  computed: {
    ...mapGetters(["API_URL", "GET_QUESTIONAIRE"]),
    pdfUrl() {
      return this.API_URL.replace(
        "/api/",
        "/pdf/pdf?r=" + this.GET_QUESTIONAIRE
      );
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.cta {
  padding: 3rem;
  &.w-full {
    width: 100%;
  }

  &.flex,
  .flex {
    display: flex;
  }

  &.flex-col,
  .flex-col-lg {
    flex-direction: column;
  }

  &.flex-row,
  .flex-row {
    flex-direction: row;
  }

  &.bg-purple {
    background-color: #37176d;
  }

  .w-6-10 {
    width: 60%;

    @media (max-width: 991.98px) {
      width: 90%;
      margin: 7px auto;
      text-align: center;
    }
  }
  .w-4-10 {
    width: 40%;

    @media (max-width: 991.98px) {
      width: 90%;
      margin: 7px auto;
      text-align: center;

      img {
        margin-top: 10px;
        max-width: 100%;
      }
    }
  }

  h2,
  p {
    color: white !important;
  }

  h2 {
    margin: inherit;
    margin-bottom: 2rem !important;
  }

  .text-left {
    text-align: left;
  }

  .mt-8 {
    margin-top: 4rem;
  }

  .cta-img {
    max-width: 300px;
  }
}

.multi-button-row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
</style>
