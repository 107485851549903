<template>
  <div class="section assesment">
    <div class="container">
      <div class="row px-4 align-items-center">
        <h2 class="col-auto">What We Measure</h2>
      </div>
    </div>

    <div class="container">
      <div class="row assesment-list">
        <div
          class="col-12 col-lg-6 assesment-list-item"
          v-for="assesment in assesments"
          :key="assesment.id"
        >
          <div class="row assesment-list-item-inner">
            <div class="col text">
              <h3 class="assesment-list-item-title tt">
                {{ assesment.id }}. {{ assesment.title }}
              </h3>
              <div class="assesment-list-item-text">
                {{ assesment.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssessmentWithoutScroll",
  data() {
    return {
      assesments: [
        {
          id: 1,
          image: "attract.svg",
          title: "Attract",
          text: "Attracting new clients is all about the “top of the funnel.” We look at your digital footprint, how well you “show up” online and where your prospective clients may be coming from.",
        },
        {
          id: 2,
          image: "close.svg",
          title: "Close",
          text: "To improve your prospect to client conversion, we’ll review best practices with CRM, how to enhance prospect and client experience, optimize nurture opportunities and more.",
        },
        {
          id: 3,
          image: "onboard.svg",
          title: "Onboard",
          text: "To elevate your onboarding process, this section will cover segmentation and adopting Cetera tools and technology.",
        },
        {
          id: 4,
          image: "wallet-share.svg",
          title: "Wallet Share",
          text: "To increase client wallet share, we focus on referrals, client satisfaction, and connections with your professional network.",
        },
      ],
    };
  },
  methods: {
    requireImage(img) {
      if (!img) return false;

      var images = require.context("../assets/", false, /\.svg$/);
      return images("./" + img);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.section {
  padding: 7vh 34px !important;
  background: center top no-repeat;
  background-image: none;
  min-height: 59vw !important;
  background-size: auto 100%;
  margin-bottom: -10vw;

  @media (max-width: 991.98px) {
    background-image: none;
    min-height: 50vh !important;
    margin-bottom: 0;
  }
}

.assesment {
  .subtitle {
    padding-left: 10vw;
    text-align: left;
  }
  .assesment-list {
    margin-top: 52px;
  }

  .assesment-list-item {
    padding: 5px 25px;
    margin-bottom: 12px;

    .assesment-list-item-inner {
      transition: all 0.43s;
      border: 1px solid #ccc;
      border-radius: 20px;
      padding: 25px;
      box-shadow: 0 0 12px #00000019;
      background: #ffffffcc;

      &:hover {
        box-shadow: 0 0 12px #00000039;
        background: #ffffffff;
      }
    }

    .assesment-list-item-image {
      min-width: 180px;

      @media (max-width: 991.98px) {
        width: 100%;

        img {
          max-width: 52%;
          margin: 12px auto;
        }
      }
    }

    .text {
      text-align: left;
    }
  }

  .assesment-list-item-title {
    color: $primary-color;
  }

  .assesment-list-item-text {
    min-height: 115px;
    font-size: 1.5vw;

    @media (max-width: 991.98px) {
      font-size: 20px;
    }
  }
}
</style>
