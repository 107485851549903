<template>
  <section
    class="container w-full flex-lg flex-col-lg cta"
    style="padding-top: 0; padding-bottom: 5rem"
  >
    <div class="top-performing-list-item-button col-auto tt">
      <a :href="pdfUrl" class="btn btn-primary small" target="_blank"
        >Save Your Results</a
      >
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      docs: [],
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["API_URL", "GET_QUESTIONAIRE"]),
    pdfUrl() {
      return this.API_URL.replace(
        "/api/",
        "/pdf/pdf?r=" + this.GET_QUESTIONAIRE
      );
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.cta {
  padding: 3rem;
  &.w-full {
    width: 100%;
  }

  &.flex,
  .flex {
    display: flex;
  }

  &.flex-col,
  .flex-col-lg {
    flex-direction: column;
  }

  &.flex-row,
  .flex-row {
    flex-direction: row;
  }

  &.bg-purple {
    background-color: #37176d;
  }

  .w-6-10 {
    width: 60%;
    display: inline-block;

    @media (max-width: 991.98px) {
      width: 90%;
      margin: 7px auto;

      p {
        text-align: center !important;
      }
    }
  }
  .w-4-10 {
    width: 40%;
    display: inline-block;
    vertical-align: top;

    img {
      max-width: 100%;
    }

    @media (max-width: 991.98px) {
      width: 90%;
      margin: 7px auto;

      p {
        text-align: center !important;
      }

      img {
        margin-top: 10px;
        max-width: 100%;
      }
    }
  }

  h2,
  p {
    color: white !important;
  }

  h2 {
    margin: inherit;
    margin-bottom: 2rem !important;
  }

  .text-left {
    text-align: left;
  }
  .btn-primary {
    border-radius: 2px !important;
  }
}
</style>
