<template>
  <div class="user-form-page section">
    <div class="container">
      <h1 v-scroll-reveal>Your Organic Growth Score</h1>
      <p class="py-4 my-4" v-scroll-reveal>
        Thank you for taking the time to complete the assessment. Please provide
        your information to reveal your Growth Score along with resources and
        tools to support you as you execute against your top 3 business growth
        areas.
      </p>

      <transition name="fade" mode="out-in" appear>
        <form method="POST">
          <div class="row py-2">
            <div class="col-lg text-start">
              <label for="firstName">First Name</label>
              <input class="form-control" id="firstName" v-model="firstName" />
            </div>
            <div class="col-lg text-start">
              <label for="lastName">Last Name</label>
              <input class="form-control" id="lastName" v-model="lastName" />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-lg text-start">
              <label for="email">Email *</label>
              <input
                class="form-control"
                id="email"
                v-model="email"
                type="email"
              />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-lg text-start">
              <label for="broker"
                >With which Cetera community are you affiliated? *</label
              >
              <select class="form-control" id="broker" v-model="broker">
                <option
                  v-for="(option, i) in brokerOptions"
                  :key="option"
                  :value="option"
                  @click="select(i)"
                  v-scroll-reveal
                >
                  {{ option }}
                </option>
              </select>
              <!-- <input class="form-control" id="broker" v-model="broker" /> -->
            </div>
            <div class="col-lg text-start">
              <label for="crd">CRD *</label>
              <input class="form-control" id="crd" v-model="crd" />
              <div class="crd-row">
                <a
                  href="https://brokercheck.finra.org/"
                  target="_blank"
                  class="underline cursor-pointer text-black"
                >
                  Find Your CRD
                </a>
                <img src="../assets/broker-check-logo.svg" />
              </div>
            </div>
          </div>
        </form>
      </transition>

      <div class="actions row mt-4 pt-4">
        <div class="col-lg return">
          <button
            @click="prevQuestion"
            v-scroll-reveal
            class="btn btn-primary pale"
          >
            PREVIOUS QUESTION
          </button>
        </div>
        <div class="col-lg advance">
          <button
            :disabled="buttonDisaled"
            @click="getScore"
            v-scroll-reveal
            class="btn btn-primary"
          >
            GET SCORE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      broker: "",
      crd: "",
      brokerOptions: [
        "Cetera Advisor Networks",
        "Cetera Financial Specialists",
        "Cetera Advisors",
        "First Allied Securities, Inc",
        // "Cetera Financial Institutions",
        "Cetera Investment Services",
        "Cetera Investors",
      ],
      isSubmitting: false,
    };
  },
  metaInfo: {
    title: "User Form",
  },
  computed: {
    ...mapGetters(["GET_QUESTIONS", "GET_ANSWERS", "API_URL"]),
    buttonDisaled() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return (
        !this.email ||
        !this.broker ||
        !this.crd ||
        !re.test(this.email) ||
        this.isSubmitting
      );
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    ...mapActions([
      "setUser",
      "setAnswered",
      "setQuestionaire",
      "setFullResponse",
    ]),
    getScore() {
      if (!this.buttonDisaled) {
        this.isSubmitting = true;
        const answers = {};
        for (const i in this.GET_QUESTIONS) {
          let question = this.GET_QUESTIONS[i];
          let answerId = this.GET_ANSWERS[i];
          let option = question.options.find((o) => o.id === answerId);
          answers[question.id] = option.id;
        }

        this.setUser({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          broker: this.broker,
          crd: this.crd,
          answers: JSON.stringify(answers),
        }).then((res) => {
          if (!res.error) {
            this.$toast.success("User and answers saved succesfully!");
            this.setQuestionaire(res.data.response);
            this.setFullResponse(res.data.full_response);
            this.setAnswered(true);
            this.$router.push({ name: "Results" });
          } else this.$toast.error(res.error);
        });
      }
    },
    prevQuestion() {
      this.$router.push({
        name: "Question",
        params: { number: this.GET_QUESTIONS.length - 1 },
      });
    },
  },
  mounted() {
    if (this.user && this.user.firstName) {
      this.broker = this.user.broker;
      this.crd = this.user.crd;
      this.email = this.user.email;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
    }
  },
};
</script>

<style lang="scss" scoped>
.user-form-page {
  .row {
    @media (max-width: 991.98px) {
      max-width: 90%;
      margin: 7px auto;
    }
  }

  .return {
    margin-bottom: 25px;
  }

  label {
    font-size: 12px;
    margin-bottom: 7px;
  }

  .form-control {
    border: none;
    background-color: #f2f2f2;
    font-size: 16px;
    padding: 12px 16px;
    color: #777;
  }

  .crd-row {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      height: 64px;

      @media (max-width: 991.98px) {
        height: 38px;
      }
    }

    a {
      cursor: pointer;
      margin-left: 3rem;

      @media (max-width: 991.98px) {
        margin-left: 1rem;
        width: auto;
        flex: none;
        font-size: 14px;
      }
    }
  }

  .advance button[disabled] {
    opacity: 0.5;
  }
}
</style>
