<template>
  <div class="section question container">
    <div v-if="showTitle && questionNumber < 2">
      <h1>Your Organic Growth Score</h1>
      <p class="py-4 my-4">
        Complete all 16 questions to get your Growth Score. We’ll identify your
        top 3 areas with greatest potential to drive your growth and value of
        your business.
      </p>
    </div>

    <transition name="fadeFast" mode="out-in" appear>
      <div key="question" class="question-inner" v-if="!loading">
        <h3 class="tt">
          <template v-if="question.type !== 'dropdown'"
            >{{ questionNumber }}.
          </template>
          {{ question.title }}
        </h3>

        <div class="options" v-if="question.type === 'multiple choice'">
          <div
            class="option row py-2 align-items-center"
            v-for="(option, i) in question.options"
            :key="option.id"
            :class="{ selected: selected === option.id }"
            @click="select(option.id)"
          >
            <span class="letter col-auto">{{ alphabet[i] }}</span>
            <div class="col text">{{ option.text }}</div>
          </div>
        </div>

        <div class="options" v-else>
          <select class="form-control w-50" v-model="selected">
            <option :value="null" disabled selected>Select your option</option>
            <option
              v-for="option in question.options"
              :key="option.id"
              :value="option.id"
              :class="{ selected: selected === option.id }"
              @click="select(option.id)"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>
      <div key="loading" class="loading" v-else></div>
    </transition>

    <div class="actions row mt-4 pt-4">
      <div class="col-lg return" v-if="showTitle">
        <button @click="prevQuestion" class="btn btn-primary pale">
          {{ questionNumber > 1 ? "PREVIOUS QUESTION" : "BACK TO HOME" }}
        </button>
      </div>
      <div class="col-lg" v-else></div>
      <div class="col-lg advance">
        <button
          :disabled="selected === null"
          @click="nextQuestion"
          class="btn btn-primary"
        >
          {{
            questionNumber === GET_QUESTIONS.length
              ? "FINISH QUESTIONS"
              : "NEXT QUESTION"
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
//import questions from "../questions.json";

export default {
  name: "Question",
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    questionNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      question: {},
      selected: null,
      loading: false,
      //questions: questions,
    };
  },
  computed: {
    ...mapGetters(["GET_QUESTIONS", "GET_ANSWERS"]),
  },
  watch: {
    questionNumber: {
      handler(n) {
        this.loading = false;
        this.selected = Number.isInteger(this.GET_ANSWERS[n - 1])
          ? this.GET_ANSWERS[n - 1]
          : null;
        this.question = this.GET_QUESTIONS[n - 1];

        setTimeout(() => {
          this.loading = false;
        }, 80);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["saveAnswer"]),
    select(item) {
      this.selected = item;
    },
    prevQuestion() {
      if (this.questionNumber > 1)
        this.$router.push({
          name: "Question",
          params: { number: this.questionNumber - 1 },
        });
      else this.$router.push({ name: "Home" });
    },
    nextQuestion() {
      this.saveAnswer({
        key: this.questionNumber - 1,
        value: this.selected,
      });

      if (this.questionNumber === this.GET_QUESTIONS.length) {
        this.$router.push({ name: "UserForm" });
      } else if (this.questionNumber === this.GET_QUESTIONS.length) {
        this.$router.push({
          name: "Question",
          params: { number: 1 },
        });
      } else {
        this.$router.push({
          name: "Question",
          params: { number: this.questionNumber + 1 },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.question {
  max-width: 1024px;
  margin: 0 auto;

  .question-inner {
    text-align: left;

    .options {
      margin-top: 34px;
      padding-left: 7vw;

      .option {
        background-color: #fff;
        transition: all 0.43s;
        padding: 12px 16px;
        margin: 3px 0;
        cursor: pointer;

        .text {
          padding: 7px;
        }

        .letter {
          transition: all 0.43s;
          display: inline-block;
          padding: 7px 12px;
          border: 1px solid $primary-color;
          color: $primary-color;
          font-weight: bold;
          margin: 0 7px -2px 0;

          &:hover {
            background-color: #eee;
          }
        }

        &:hover {
          background-color: $primary-color;
          color: #fff;

          .letter {
            color: #fff;
            border-color: #fff;
          }
        }

        &.selected {
          background-color: #fbfbfb;
          color: $text-color;

          .letter {
            background-color: $primary-color;
            color: #fff;
          }
        }
      }
    }
  }

  .actions {
    max-width: 100%;
    padding: 0;
    margin: 0;

    .return {
      @media (max-width: 991.98px) {
        margin-bottom: 25px;
      }
    }
  }

  .question-list-item-title {
    color: $primary-color;
  }
}
</style>
