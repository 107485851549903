<template>
  <section class="w-full py-4 questions answered container">
    <h2 class="text-center py-3" v-scroll-reveal>
      How you answered your assessment
    </h2>

    <div class="py-4 text-start" v-scroll-reveal>
      Below are the answers and the corresponding rating for how you are
      performing. They are ranked by status from highest opportunity to lowest
      with your best performing categories at the bottom.
    </div>

    <div
      style="font-weight: bold"
      class="questiom text-start header row pb-2 border-bottom mb-3"
      v-scroll-reveal
    >
      <div class="col-auto number" style="width: 20px"></div>
      <div class="col-6 title">QUESTION</div>
      <div class="col category">CATEGORY</div>
      <div class="col status">STATUS</div>
      <div class="col guide">GUIDE</div>
    </div>

    <div
      v-for="(question, i) in filteredQuestions"
      :key="question.id"
      class="questiom text-start row"
      v-scroll-reveal
    >
      <div class="col-auto number" style="width: 20px">
        {{ i + 1 }}
      </div>
      <div class="col-6 title">
        <strong>{{ question.title }}</strong>
        <p class="mt-2">Answer: {{ question.answerText }}</p>
      </div>
      <div class="col category">{{ getText(question.category) }}</div>
      <div class="col status">
        {{
          question.scored > 0.66
            ? "Optimized"
            : question.scored > 0.33
            ? "Active"
            : "Visible"
        }}
      </div>
      <div class="col guide">
        <a :href="question.guide_url" target="_blank"> View Guide </a>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      docs: [],
    };
  },
  methods: {
    getText(cat) {
      if (cat === "Aquisition") {
        return "Attract";
      }

      if (cat === "Conversion") {
        return "Close";
      }

      if (cat === "Onboarding") {
        return "Onboard";
      }

      return cat;
    },
  },
  computed: {
    ...mapGetters([
      "API_URL",
      "GET_QUESTIONAIRE",
      "GET_QUESTIONS",
      "GET_ANSWERS",
    ]),
    pdfUrl() {
      return this.API_URL.replace(
        "/api/",
        "/pdf/pdf?r=" + this.GET_QUESTIONAIRE
      );
    },
    filteredQuestions() {
      const filtered = this.GET_QUESTIONS.filter((question) => {
        return question.category !== "Bonus";
      }).map((question) => {
        const answer = question.options.find((option) => {
          return option.id === question.answer;
        });

        return {
          ...question,
          answerText: answer.text,
        };
      });

      return _.orderBy(filtered, ["scored"], ["asc"]);
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "../scss/colors";

.answered {
  max-width: 1420px;
  margin: 52px auto;
  padding: 3rem;

  a {
    font-weight: bold;
    transition: all 0.43s;
    text-decoration: none;
    color: $primary-color;

    &:hover {
      color: darken($button-color, 10);
    }
  }
}
</style>
