<template>
  <div class="container">
    <div class="full-row">
      <div class="content">
        <div class="header-row">
          <h2>Successfully Onboard</h2>
          <p>
            To elevate your onboarding process, this section will cover
            segmentation and adopting Cetera tools and technology.
          </p>
        </div>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row purple">
      <div class="content">
        <h3>Business Recommendations</h3>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row">
      <div class="content main-content">
        <p>In this area we’ll cover:</p>
        <ul>
          <li>
            CRM and Segmentation – Just as every client and prospect is
            different, your message to them must be different as well. Since you
            don’t have time to write custom emails to every person in your
            contact list, CRM tools and segmentation can help. In this guide,
            you’ll learn why it’s essential to stay in touch with prospective
            and current clients in ways that are meaningful, yet time-efficient.
          </li>
          <li>
            Cetera Tools and Technology – Business technology can help improve
            how you assist clients in achieving their financial goals. In this
            guide, you'll learn how technology can help boost lead generation,
            streamline administrative tasks and improve the client experience.
          </li>
        </ul>
      </div>
      <div class="blank"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.full-row {
  display: flex;
  flex-direction: row;

  &.purple {
    background-color: #37176d;
    color: white;

    .content {
      padding: 0.5rem;
    }

    h3 {
      color: white !important;
      text-align: left;
      padding-left: 2rem;
      padding-bottom: 0;
      font-weight: 100;
    }
  }

  .content {
    width: 74%;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    .header-row {
      display: flex;
      flex-direction: column;

      h2 {
        margin-left: 1rem !important;
      }

      p {
        padding: 1rem;
        text-align: left;
      }
    }

    &.main-content {
      padding: 2rem;
      padding-top: 3rem;
      text-align: left;

      @media (max-width: 991.98px) {
        padding: 3rem 0.3rem 2rem;
      }

      ul {
        margin: 1rem;
        text-align: left;

        li {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .blank {
    width: 26%;

    @media (max-width: 991.98px) {
      width: 0;
    }
  }
}
</style>
