<template>
  <div class="section leverage">
    <div class="container">
      <div class="row px-4 align-items-center">
        <div class="col-auto">
          <a>
            <img src="../assets/leverage2.svg" />
          </a>
        </div>
        <div class="col-lg text">
          <h2>Learn and Grow</h2>
          <p>
            Leverage our Organic Growth guides to support your ongoing growth.
            These easy-to-follow documents can help you sharpen your skills and
            become more effective in your day-to-day operations.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Leverage",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.leverage {
  background-color: #fbfbfb;

  .container {
    max-width: 1024px;
    margin: 0 auto;

    .text {
      text-align: left;
      padding-left: 7vw;
    }
  }

  .leverage-list-item-title {
    color: $primary-color;
  }
}
</style>
