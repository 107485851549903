import Vue from "vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Add icons to Library
library.add(faArrowDown);

import VueScrollReveal from "vue-scroll-reveal";
import VueScrollTo from "vue-scrollto";
import VueMeta from "vue-meta";
import VueAnalytics from "vue-analytics";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
//import 'vue-toast-notification/dist/theme-sugar.css';

import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueAnalytics, {
  id: "G-5LVBD90WNE",
});

Vue.use(VueToast, {
  position: "top-right",
});

Vue.use(VModal);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueScrollTo);
Vue.use(VueScrollReveal, {
  class: "v-scroll-reveal",
  duration: 800,
  scale: 0.7,
  distance: "25px",
  mobile: false,
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
