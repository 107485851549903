<template>
  <div class="flex flex-row justify-center p-4">
    <a
      href="https://myceterasmartworks.com/Public/Portal/Content.aspx?ContentId=30183607"
      target="_blank"
      title="View Growth360 FAQ's"
      class="tt cursor-pointer mx-4"
      >View Growth360 FAQ's</a
    >
    <a
      href="https://www.cetera.com/privacy-policy"
      target="_blank"
      title="Terms of Service"
      class="tt cursor-pointer mx-4"
      style="display: none"
      >Terms</a
    >
    <a
      href="https://www.cetera.com/privacy-policy"
      target="_blank"
      title="Privacy Policy"
      class="tt cursor-pointer mx-4"
      >Privacy</a
    >
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;

  &-row {
    flex-direction: row;
  }
}

.justify-center {
  justify-content: center;
}

a {
  color: #37176d;
  font-size: 16px;
  width: 180px;
  margin: 0 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.p-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
