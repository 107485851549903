<template>
  <div id="app" class="container-fluid">
    <transition name="fade" appear>
      <SmallHeader v-if="$route.name !== 'Home' && $route.name !== 'Results'" />
    </transition>

    <transition name="fade" mode="out-in" appear>
      <router-view v-if="loaded" />
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import SmallHeader from "./components/SmallHeader.vue";

export default {
  components: {
    SmallHeader,
  },
  data() {
    return {
      loaded: false,
    };
  },
  metaInfo: {
    title: "",
    titleTemplate: "%s - Growth360",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...mapGetters(["API_URL", "GET_QUESTIONS", "IS_ANSWERED"]),
  },
  watch: {
    $route: {
      handler(route) {
        // if (this.IS_ANSWERED && route.name !== "Results")
        // this.$router.push({ name: "Results" });
        if (!this.IS_ANSWERED && route.name === "Results") {
          this.$router.push({ name: "Question", params: { number: 1 } });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  beforeMount() {
    if (this.GET_QUESTIONS.length === 0)
      axios.get(this.API_URL + "questions").then((res) => {
        this.saveQuestions(res.data);
        this.loaded = true;
      });
    else this.loaded = true;

    if (!this.GET_AVERAGES)
      axios.get(this.API_URL + "averages/latest").then((res) => {
        this.saveAverages(res.data);
      });
  },
  methods: {
    ...mapActions(["saveQuestions", "saveAverages"]),
  },
};
</script>

<style lang="scss">
@import "scss/colors";
@import "scss/fonts";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500&display=swap");

button {
  outline: none !important;
}

#app {
  font-family: "Red Hat Display", Arial, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
  padding: 0 !important;
  font-size: 20px;
  line-height: 1.4;

  @media screen and (max-width: 800px) {
    font-size: 18px;
    line-height: 1.2;
  }

  h1,
  h2,
  h3 {
    color: $primary-color;
    font-family: "FinancierDisplay";
  }

  h1 {
    font-size: 60px;

    @media (max-width: 991.98px) {
      font-size: 48px;
      text-align: center;
    }
  }

  h2 {
    font-size: 48px;
    max-width: 70vw;
    margin: 0 auto 0.5rem;

    @media (max-width: 991.98px) {
      font-size: 32px;
      text-align: center;
    }
  }

  h3 {
    @media (max-width: 991.98px) {
      font-size: 20px;
      text-align: center;
      max-width: 90%;
      margin: 7px auto 25px;
    }
  }

  .btn-close-modal {
    color: #fff;
    background-color: #a3230daa;
    transition: all 0.43s;

    &:hover {
      background-color: #c23921;
    }
  }

  #span-logo {
    color: #fff;
    padding-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Asap Medium", Arial !important;
    font-size: 48px !important;
    margin-bottom: 0px;

    strong {
      font-family: "Asap Bold", Arial !important;
    }

    &.white {
      color: #fff;
    }

    @media screen and (max-width: 800px) {
      font-size: 34px !important;
      justify-content: center;
    }
  }

  .tt {
    font-family: "TT Norms", Arial !important;
  }

  .btn-primary {
    transition: all 0.43s;
    background-color: $button-color;
    border-color: $button-color;
    color: #fff;
    border-radius: 7px;
    padding: 16px 34px;
    min-width: 270px;
    text-align: center;
    outline: none !important;
    box-shadow: 4px 4px 4px #00000022;
    opacity: 1 !important;
    text-transform: uppercase;
    font-weight: 500;

    &.small {
      padding: 16px 20px;
      min-width: 160px;
    }

    &:hover {
      background-color: darken($button-color, 10);
      border-color: darken($button-color, 10);
      box-shadow: 3px 3px 12px #00000033;
    }

    &.pale {
      background-color: $pale-button-color;
      border-color: $pale-button-color;

      &:hover {
        background-color: darken($pale-button-color, 10);
        border-color: darken($pale-button-color, 10);
        box-shadow: 3px 3px 12px #00000033;
      }
    }

    &:disabled {
      opacity: 0.7 !important;
      border-color: #eee;
      background-color: #eee;
      color: #999;
      cursor: not-allowed !important;
      box-shadow: none;

      &:hover {
        cursor: not-allowed !important;
      }
    }
  }

  .divider {
    background-color: $primary-color;
    width: 100%;
    height: 7px;
    margin: 16px 0 34px;

    &.small {
      width: 50%;
      margin: 16px auto 34px;
      height: 3px;
      min-width: 700px;

      @media (max-width: 991.98px) {
        width: 70%;
        min-width: 0;
      }
    }
  }

  .section {
    padding: 7vh 0;
    min-height: 50vh;

    &.top-opportunities,
    &.top-performing {
      padding-top: 10px;
    }
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 74px 93px 0 93px;
    border-color: #f6c371 transparent transparent transparent;
    margin: 25px auto;
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);

    @media (max-width: 991.98px) {
      border-width: 37px 46px 0 46px;
    }

    &.blue {
      border-color: #8399ff transparent transparent transparent;
      border-width: 79px 23px 0 23px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fadeFast-enter-active,
.fadeFast-leave-active {
  transition: opacity 0.2s;
}
.fadeFast-enter,
.fadeFast-leave-to {
  opacity: 0;
}

.loading {
  color: $primary-color;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
