<template>
  <div class="section scales">
    <h2>See How You Stack Up</h2>
    <p>
      Take the assessment to gauge your skill and acumen in different areas of
      your business.
    </p>

    <div class="container">
      <div class="row scales-list">
        <div
          class="col-lg scales-list-item"
          v-for="scale in scales"
          :key="scale.id"
        >
          <div class="scales-list-item-image">
            <img :src="requireImage(scale.image)" />
          </div>
          <h3 class="scales-list-item-title tt">
            {{ scale.title }}
          </h3>
          <div class="scales-list-item-text">
            {{ scale.text }}
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: "Scales",
  data() {
    return {
      scales: [
        {
          id: 1,
          image: "cetera-meter-low.svg",
          title: "Visible",
          text: "The first step is getting familiar with using current marketing techniques and tools.",
        },
        {
          id: 2,
          image: "cetera-meter-mid.svg",
          title: "Active",
          text: "The next step is staying active with your marketing and using some automated marketing tools.",
        },
        {
          id: 3,
          image: "cetera-meter-high.svg",
          title: "Optimized",
          text: "Learn about some quick options to optimize your marketing efforts for even greater success. ",
        },
      ],
    };
  },
  methods: {
    requireImage(img) {
      if (!img) return false;

      var images = require.context("../assets/", false, /\.svg$/);
      return images("./" + img);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.scales {
  .container {
    max-width: 1024px;
    margin-top: 52px;
  }

  .divider {
    max-width: 80%;
    margin: 43px auto !important;
  }

  .scales-list-item-image {
    img {
      max-width: 200px;
    }
  }

  .scales-list-item-title {
    color: $primary-color;
  }

  .scales-list-item-text {
    width: 90%;
    margin: 16px auto;
  }
}
</style>
