<template>
  <div class="section area">
    <div class="container">
      <div class="row px-4 align-items-center">
        <h2 v-scroll-reveal class="col-auto">
          Your Score By
          <br />
          the Numbers
        </h2>
        <p v-scroll-reveal class="col-lg subtitle pr-4">
          Below you will find four business categories and how your firm ranked
          in each. In the section "Top Opportunities for Growth" you'll see
          three opportunities based on your assessment.
        </p>
      </div>

      <div v-scroll-reveal class="divider"></div>
      <div class="floating-link row justify-content-end" style="">
        <a
          v-on:click="toogleAccordion('accordion')"
          v-scroll-reveal
          class="col-auto link show-accordion"
        >
          Learn More About the Program
          <svg
            enable-background="new 0 0 29 14"
            height="10px"
            id="Layer_1"
            version="1.1"
            viewBox="0 0 29 14"
            width="29px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <polygon fill="#737b7d" points="0.15,0 14.5,14.35 28.85,0 " />
          </svg>
        </a>
      </div>
    </div>
    <div class="container my-4 graphs" v-if="!isMobile">
      <h2 v-scroll-reveal class="col-auto py-4">
        Scoring Across Four Business Categories
      </h2>
      <p v-scroll-reveal class="col-auto">
        Click an area title to view more information.
      </p>

      <div v-scroll-reveal class="row">
        <div v-scroll-reveal class="col-auto y-axis">
          <div v-scroll-reveal class="score-type">
            Optmized and functioning at a high level
          </div>
          <div v-scroll-reveal class="score-type">
            Actively engaged with some opportunity
          </div>
          <div v-scroll-reveal class="score-type">
            Opportunity to expand capabilities
          </div>
          <div
            v-scroll-reveal
            class="score-type fastest-range font-weight-normal"
          >
            Fastest Growing <br />
            Advisors Range
          </div>
        </div>
        <div v-scroll-reveal class="col-lg graph">
          <div v-scroll-reveal class="graph-areas row align-items-end">
            <div v-scroll-reveal class="col-lg">
              <div :style="{ height: getHeight(0) }" class="col-inner first">
                <span class="percent-text">{{ getPercent(0) }}%</span>
              </div>
              <div
                class="col-avg first"
                :title="'Average: ' + avgPercent(0) + '%'"
                :style="{
                  marginTop: getMarginTop(0),
                  marginBottom: getMarginBottom(0),
                }"
              ></div>
            </div>
            <div v-scroll-reveal class="col-lg">
              <div :style="{ height: getHeight(1) }" class="col-inner second">
                <span class="percent-text">{{ getPercent(1) }}%</span>
              </div>
              <div
                class="col-avg second"
                :title="'Average: ' + avgPercent(1) + '%'"
                :style="{
                  marginTop: getMarginTop(1),
                  marginBottom: getMarginBottom(1),
                }"
              ></div>
            </div>
            <div v-scroll-reveal class="col-lg">
              <div :style="{ height: getHeight(2) }" class="col-inner third">
                <span class="percent-text">{{ getPercent(2) }}%</span>
              </div>
              <div
                class="col-avg third"
                :title="'Average: ' + avgPercent(2) + '%'"
                :style="{
                  marginTop: getMarginTop(2),
                  marginBottom: getMarginBottom(2),
                }"
              ></div>
            </div>
            <div v-scroll-reveal class="col-lg">
              <div :style="{ height: getHeight(3) }" class="col-inner fourth">
                <span class="percent-text">{{ getPercent(3) }}%</span>
              </div>
              <div
                class="col-avg fourth"
                :title="'Average: ' + avgPercent(3) + '%'"
                :style="{
                  marginTop: getMarginTop(3),
                  marginBottom: getMarginBottom(3),
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div v-scroll-reveal class="row">
        <div v-scroll-reveal class="col-auto y-axis"></div>
        <div v-scroll-reveal class="col-lg x-axis">
          <div v-scroll-reveal class="graph-areas-names row">
            <div
              @click="toogleAccordion('acquisition')"
              v-scroll-reveal
              class="col-lg cursor-pointer chart-button"
            >
              Attract
            </div>
            <div
              @click="toogleAccordion('conversion')"
              v-scroll-reveal
              class="col-lg cursor-pointer chart-button"
            >
              Close
            </div>
            <div
              @click="toogleAccordion('onboarding')"
              v-scroll-reveal
              class="col-lg cursor-pointer chart-button"
            >
              Onboard
            </div>
            <div
              @click="toogleAccordion('wallet')"
              v-scroll-reveal
              class="col-lg cursor-pointer chart-button"
            >
              Wallet Share
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-4 graphs-mobile" v-else>
      <h2 v-scroll-reveal class="col-auto py-4">
        Scoring Across Four Business Categories
      </h2>
      <p v-scroll-reveal class="col-auto">
        Click an area title to view more information.
      </p>

      <div v-scroll-reveal class="row">
        <div
          @click="toogleAccordion('acquisition')"
          v-scroll-reveal
          class="col-lg cursor-pointer chart-button"
        >
          Attract
        </div>
        <div v-scroll-reveal class="col-lg">
          <div
            :style="{ width: getPercent(0) + '%' }"
            class="col-inner mobile first"
          >
            {{ getPercent(0) }}%
          </div>
        </div>

        <div
          @click="toogleAccordion('conversion')"
          v-scroll-reveal
          class="col-lg cursor-pointer chart-button"
        >
          Close
        </div>
        <div v-scroll-reveal class="col-lg">
          <div
            :style="{ width: getPercent(1) + '%' }"
            class="col-inner mobile second"
          >
            {{ getPercent(1) }}%
          </div>
        </div>

        <div
          @click="toogleAccordion('onboarding')"
          v-scroll-reveal
          class="col-lg cursor-pointer chart-button"
        >
          Onboard
        </div>
        <div v-scroll-reveal class="col-lg">
          <div
            :style="{ width: getPercent(2) + '%' }"
            class="col-inner mobile third"
          >
            {{ getPercent(2) }}%
          </div>
        </div>

        <div
          @click="toogleAccordion('wallet')"
          v-scroll-reveal
          class="col-lg cursor-pointer chart-button"
        >
          Wallet Share
        </div>
        <div v-scroll-reveal class="col-lg">
          <div
            :style="{ width: getPercent(3) + '%' }"
            class="col-inner mobile fourth"
          >
            {{ getPercent(3) }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Areas",
  data() {
    return {
      areas: ["2px", "2px", "2px", "2px"],
      percents: [0, 0, 0, 0],
      accordionHeight: "500vh",
    };
  },
  computed: {
    ...mapGetters(["GET_QUESTIONS", "GET_ANSWERS", "GET_AVERAGES"]),
    isMobile() {
      return window.innerWidth <= 800;
    },
  },
  beforeMount() {
    const cat1questions = this.GET_QUESTIONS.filter(
      (q) => q.category === "Aquisition"
    );
    const cat2questions = this.GET_QUESTIONS.filter(
      (q) => q.category === "Conversion"
    );
    const cat3questions = this.GET_QUESTIONS.filter(
      (q) => q.category === "Onboarding"
    );
    const cat4questions = this.GET_QUESTIONS.filter(
      (q) => q.category === "Wallet Share"
    );

    const questions = this.GET_QUESTIONS;

    let res1 = 0,
      res2 = 0,
      res3 = 0,
      res4 = 0;

    questions.forEach((question, id) => {
      let answerId = this.GET_ANSWERS[id];
      let option = question.options.find((o) => o.id == answerId);
      if (question.category === "Aquisition") {
        res1 += option.value;
      }
      if (question.category === "Conversion") {
        res2 += option.value;
      }
      if (question.category === "Onboarding") {
        res3 += option.value;
      }
      if (question.category === "Wallet Share") {
        res4 += option.value;
      }
    });

    this.percents[0] = (res1 * 100) / cat1questions.length;
    this.areas[0] = (res1 * 420) / cat1questions.length;

    this.percents[1] = (res2 * 100) / cat2questions.length;
    this.areas[1] = (res2 * 420) / cat2questions.length;

    this.percents[2] = (res3 * 100) / cat3questions.length;
    this.areas[2] = (res3 * 420) / cat3questions.length;

    this.percents[3] = (res4 * 100) / cat4questions.length;
    this.areas[3] = (res4 * 420) / cat4questions.length;
  },
  methods: {
    getPercent(key) {
      let percent = this.percents[key];
      if (percent > 95) {
        percent = 95;
      }

      if (percent < 25) {
        percent = 25;
      }
      return percent.toFixed(0);
    },
    getHeight(key) {
      let height = this.areas[key];
      if (height > 399) {
        height = 399;
      }

      if (height < 105) {
        height = 105;
      }
      return height + "px";
    },
    avgPercent(key) {
      let avg;
      switch (key) {
        case 0:
          avg = "aquisition";
          break;
        case 1:
          avg = "conversion";
          break;
        case 2:
          avg = "onboarding";
          break;
        case 3:
          avg = "wallet_share";
          break;
      }

      return this.GET_AVERAGES[avg];
    },
    getMarginTop(key) {
      let avg;
      switch (key) {
        case 0:
          avg = "aquisition";
          break;
        case 1:
          avg = "conversion";
          break;
        case 2:
          avg = "onboarding";
          break;
        case 3:
          avg = "wallet_share";
          break;
      }

      const res = this.GET_AVERAGES[avg] * 4.2 + 25;

      return -res + "px";
    },
    getMarginBottom(key) {
      let avg;
      switch (key) {
        case 0:
          avg = "aquisition";
          break;
        case 1:
          avg = "conversion";
          break;
        case 2:
          avg = "onboarding";
          break;
        case 3:
          avg = "wallet_share";
          break;
      }

      const res = this.GET_AVERAGES[avg] * 4.2 - 25;

      return res + "px";
    },
    requireImage(img) {
      if (!img) return false;

      var images = require.context("../assets/", false, /\.svg$/);
      return images("./" + img);
    },
    toogleAccordion(accordion) {
      this.$store.dispatch("toggleAccordionContent", accordion);
      this.$modal.show("accordion-dropdown");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.accordion-main {
  transition: all 0.43s;
}

.area {
  .subtitle {
    padding-left: 10vw;
    text-align: left;

    @media (max-width: 991.98px) {
      padding-left: 0;
      font-size: 16px;
      text-align: center;
    }
  }
  .area-list {
    margin-top: 52px;
  }

  .area-list-item {
    .area-list-item-image {
      min-width: 180px;
    }

    .text {
      text-align: left;
    }
  }

  .area-list-item-title {
    color: $primary-color;
  }

  .graphs-mobile {
    width: 90%;

    .chart-button {
      text-align: left;
    }

    .col-inner {
      background-color: #ddc6da;
      display: flex;

      &.mobile {
        padding: 25px;
        margin: 7px 0 16px;
      }

      &.second {
        background-color: #cfe5f3;
      }

      &.third {
        background-color: #ddf8e9;
      }

      &.fourth {
        background-color: #cfc0f9;
      }
    }
  }

  .graph {
    border-left: 1px solid #555;
    border-bottom: 1px solid #555;
    padding-bottom: 16px;

    .percent-text {
      padding: 3px 0px;
      margin: -16px auto 0;
      display: block;
      width: calc(100% - 30px);
      text-align: center;
      position: absolute;
      z-index: 1010;
      bottom: 12px;
      font-weight: bold;
      color: $primary-color;
    }

    .graph-areas {
      height: 430px;

      .col-lg {
        padding: 0 16px;

        .col-inner {
          transition: all 0.43s;
          background-color: #ddc6da;
          min-height: 86px;

          &.second {
            background-color: #cfe5f3;
          }

          &.third {
            background-color: #ddf8e9;
          }

          &.fourth {
            background-color: #cfc0f9;
          }
        }

        .col-avg {
          width: 100%;
          height: 50px;
          border-bottom: dotted #333;
          border-top: dotted #333;
        }
      }
    }
  }

  .col-auto.y-axis {
    width: 300px;

    .score-type {
      height: 107px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;
      width: 100%;
      padding-left: 52px;
      color: #333;
      font-weight: 700;

      &.fastest-range {
        text-align: center;
        font-weight: 400;
        border-top: 3px dotted;
        width: 80%;
        border-bottom: 3px dotted;
        margin-right: 0;
        float: right;
      }
    }
  }

  .col.x-axis {
    padding-top: 16px;
  }

  .link {
    cursor: pointer;

    &.show-accordion {
      border: 1px solid #3a0a58;
      margin: -35px 25px 0 0;
      padding: 12px 34px;
      color: #737b7d;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .chart-button {
    visibility: visible;
    color: #37176d;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.8s cubic-bezier(0.5, 0, 0, 1) 0s,
      transform 0.8s cubic-bezier(0.5, 0, 0, 1) 0s, font-size 0.6s ease-in-out;
    text-decoration: underline;

    &:hover {
      padding-bottom: 5px;
      font-size: 1.5rem;
    }
  }
}
</style>
