<template>
  <div class="section top-performing">
    <h2 v-scroll-reveal>Current Top-Performing Areas</h2>

    <div v-scroll-reveal class="divider small"></div>

    <p class="container max-900" v-scroll-reveal>
      These are your highest-scoring areas, as measured by the Growth360&deg;
      assessment. The corresponding guides can help you take these aspects of
      your business even further.
    </p>

    <div class="container py-4">
      <div
        class="row py-1 top-performing-list-item align-items-center"
        v-for="opportunity in topPerforming"
        v-scroll-reveal
        :key="opportunity.id"
      >
        <div class="top-performing-list-item-image col-auto">
          <img
            v-scroll-reveal
            :src="requireImage('cetera-meter-high-ryg.svg')"
            class="dial-img"
          />
        </div>
        <div class="top-performing-list-item-text text-start col-lg">
          <h5 class="grey-title">
            <template v-if="opportunity.category === 'Aquisition'">
              Driving Attraction
            </template>
            <template v-else-if="opportunity.category === 'Onboarding'">
              Successfully Onboard
            </template>
            <template v-else-if="opportunity.category === 'Conversion'">
              Focus on Closing
            </template>
            <template v-else> Getting More Wallet Share </template>
          </h5>
          <h4 class="tt">{{ opportunity.guide_title }}</h4>
          <p>{{ opportunity.guide_description }}</p>
        </div>
        <div class="top-performing-list-item-button col-auto tt">
          <button
            @click="openGuide(opportunity.guide_url)"
            class="btn btn-primary small"
          >
            View Guide
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "topPerforming",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["GET_ANSWERS", "GET_QUESTIONS", "GET_FULL_RESPONSE"]),
    topPerforming() {
      const fr = this.GET_FULL_RESPONSE;
      return fr.top_performing_areas;
    },
  },
  methods: {
    requireImage(img) {
      if (!img) return false;

      var images = require.context("../assets/", false, /\.svg$/);
      return images("./" + img);
    },
    openGuide(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.top-performing {
  .container {
    max-width: 1024px;

    &.max-900 {
      // max-width: 900px;

      @media (max-width: 991.98px) {
        width: 90%;
        margin: 7px auto;
      }
    }
  }

  .top-performing-list-item {
    margin: 20px 0;
  }

  .top-performing-list-item-button {
    @media (max-width: 991.98px) {
      width: 90%;
      margin: 7px auto;
    }
  }

  .top-performing-list-item-image {
    padding-right: 20px;

    @media (max-width: 991.98px) {
      padding: 0;
      margin: 12px auto;
    }
  }

  .top-performing-list-item-text {
    font-size: 24px;
    width: 180px;
    margin: 0 auto;
    font-size: 14px;
    padding-right: 20px;

    @media (max-width: 991.98px) {
      width: 90%;
      text-align: center !important;
    }

    h4 {
      color: $primary-color;

      @media (max-width: 991.98px) {
        text-align: center;
      }
    }
  }

  .dial-img {
    width: 240px;
    max-width: 240px;
  }

  .grey-title {
    font-family: "FinancierDisplay";
    color: #737b7d;
  }
}
</style>
