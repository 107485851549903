<template>
  <div class="container">
    <div class="full-row">
      <div class="content">
        <div class="header-row">
          <h2>Driving Attraction</h2>
          <p>
            Attracting new clients is all about the “top of the funnel.” We look
            at your digital footprint, how well you “show up” online and where
            your prospective clients may be coming from.
          </p>
        </div>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row purple">
      <div class="content">
        <h3>Business Recommendations</h3>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row">
      <div class="content main-content">
        <p>In this area we’ll cover:</p>
        <ul>
          <li>
            Branding - Your brand identifies your company as financial
            professionals who clients want to trust and work with. In this
            guide, you'll learn what a strong brand is, why different elements
            of your brand are important and how they work to help you attract
            new clients while creating and maintaining positive relationships
            with your existing clients.
          </li>
          <li>
            Website Best Practices – As more people use online resources to find
            companies they want to work with, it’s critical to have an effective
            website and online presence. In this guide, you’ll learn why your
            website is one of your best resources for attracting prospective
            clients and retaining current ones.
          </li>
          <li>
            Social Media – Social media is a cost-effective resource you can use
            to increase your client base and find new opportunities for growth.
            In this guide, you’ll learn why having an active social media
            presence can help you engage with prospective and existing clients
            and build more trusting relationships.
          </li>
          <li>
            Digital Marketing - As the world becomes more reliant on technology,
            it seems like marketing will follow the trend. In this guide, you'll
            learn how to use digital marketing strategies, why digital marketing
            can help your company attract more qualified leads and how to
            utilize it to engage with your existing clients.
          </li>
          <li>
            Being Active in Your Community - Research suggests that prospective
            and existing clients want to work with companies that feel a sense
            of social responsibility and community involvement and have a larger
            purpose that goes beyond what they do day-to-day. In this guide,
            you'll learn why it's essential to connect with people in your
            community and have a strong community presence.
          </li>
        </ul>
      </div>
      <div class="blank"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.full-row {
  display: flex;
  flex-direction: row;

  &.purple {
    background-color: #37176d;
    color: white;

    .content {
      padding: 0.5rem;
    }

    h3 {
      color: white !important;
      text-align: left;
      padding-left: 2rem;
      padding-bottom: 0;
      font-weight: 100;
    }
  }

  .content {
    width: 74%;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    .header-row {
      display: flex;
      flex-direction: column;

      h2 {
        margin-left: 1rem !important;
      }

      p {
        padding: 1rem;
        text-align: left;
      }
    }

    &.main-content {
      padding: 2rem;
      padding-top: 3rem;
      text-align: left;

      @media (max-width: 991.98px) {
        padding: 3rem 0.3rem 2rem;
      }

      ul {
        margin: 1rem;
        text-align: left;

        li {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .blank {
    width: 26%;

    @media (max-width: 991.98px) {
      width: 0;
    }
  }
}
</style>
