<template>
  <div class="results">
    <Hero
      :title="heroTitle"
      :hasButton="hero.hasButton"
      :buttonText="hero.buttonText"
      :isResults="true"
      :hideLogo="true"
    />
    <Gauge :score="score" />
    <section class="results__container">
      <Areas :answers="GET_ANSWERS" />
      <DownloadButtonRow />
      <TopOpportunities :answers="GET_ANSWERS" />
      <!-- :topOpportunities="topOpportunities" -->
      <DownloadButtonRow />
      <TopPerforming :answers="GET_ANSWERS" />
      <!-- :topPerforming="topPerforming" -->
      <DownloadButtonRow />
      <Cta />
      <Answered />
      <Retake />
    </section>

    <AccordionDropdown />
    <ScrollToSee />
    <Footer />
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Gauge from "@/components/Gauge.vue";
import Areas from "@/components/Areas.vue";
import Cta from "@/components/Cta.vue";
import Answered from "@/components/Answered.vue";
import ScrollToSee from "@/components/ScrollToSee.vue";
import AccordionDropdown from "@/components/AccordionDropdown.vue";
import TopOpportunities from "@/components/TopOpportunities.vue";
import TopPerforming from "@/components/TopPerforming.vue";
import Retake from "@/components/Retake.vue";
import Footer from "@/components/Footer.vue";
import DownloadButtonRow from "@/components/DownloadButtonRow.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Results",
  components: {
    Hero,
    Gauge,
    Areas,
    Answered,
    ScrollToSee,
    AccordionDropdown,
    TopOpportunities,
    TopPerforming,
    DownloadButtonRow,
    Cta,
    Retake,
    Footer,
  },
  metaInfo: {
    title: "Results",
  },
  data() {
    return {
      hero: {
        title: "Assessment Results",
        bigTitle: true,
        hasButton: false,
        image: "results",
      },
      score: 0,
      topOpportunities: [],
      topPerforming: [],
    };
  },
  computed: {
    ...mapGetters([
      "GET_ANSWERS",
      "GET_QUESTIONS",
      "API_URL",
      "GET_USER",
      "GET_FULL_RESPONSE",
    ]),
    user() {
      return this.$store.state.user;
    },
    heroTitle() {
      // title: "Assessment Results",
      if (!this.user || !this.user.firstName) {
        return `Assessment Results`;
      }
      return `${this.user.firstName} ${this.user.lastName}'s Results`;
    },
  },
  methods: {
    ...mapActions(["saveResults"]),
  },
  beforeMount() {
    let total = 0;
    this.GET_ANSWERS.forEach((answer, i) => {
      if (this.GET_QUESTIONS[i])
        total += this.GET_QUESTIONS[i].options.find(
          (o) => o.id === answer
        ).value;
    });

    total = this.GET_FULL_RESPONSE.score * 100;

    if (total > 95) total = 95;
    if (total < 25) total = 25;
    this.score = total;

    const questions = this.GET_QUESTIONS.filter((q) => q.category !== "Bonus");
    const byCategory = {};

    questions.forEach((question, i) => {
      question.scored = question.options.find(
        (o) => o.id === this.GET_ANSWERS[i]
      ).value;
      question.answer = this.GET_ANSWERS[i];

      if (byCategory[question.category]) {
        byCategory[question.category].questions.push(question);
        byCategory[question.category].total += question.scored;
        byCategory[question.category].rel =
          byCategory[question.category].total /
          byCategory[question.category].questions.length;
      } else {
        byCategory[question.category] = {
          questions: [question],
          total: question.scored,
          rel: question.scorded,
        };
      }
    });

    this.topOpportunities = questions
      .sort((a, b) => (a.scored > b.scored ? 1 : -1))
      .slice(0, 3);

    const bottom = this.topOpportunities.map((t) => t.id);

    const top = questions.sort((a, b) => (a.scored > b.scored ? 1 : -1));

    this.topOpportunities = [];
    const topQuestions = [];
    Object.entries(byCategory).forEach((c) => {
      const q = c[1].questions
        .sort((a, b) => (a.scored > b.scored ? 1 : -1))
        .slice(0, 1);

      topQuestions.push({
        ...q[0],
        rel: c[1].rel,
      });
    });

    this.topOpportunities = topQuestions
      .sort((a, b) => (a.rel > b.rel ? 1 : -1))
      .slice(0, 3);

    const topUrls = this.topOpportunities.map((r) => r.guide_title);

    let i = 0;
    for (const id in top) {
      if (i < 3) {
        let q = top[id];
        if (!bottom.includes(q.id) && !topUrls.includes(q.guide_title)) {
          this.topPerforming.push(q);
          i++;
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.results {
  &__container {
    // max-width: 900px;
    margin: auto;
  }
}
</style>
