<template>
  <div class="home">
    <Hero
      :title="hero.title"
      :content="hero.content"
      :hasButton="hero.hasButton"
      :buttonText="hero.buttonText"
    />
    <!-- <Opportunities /> -->
    <!-- <TopAdvisors /> -->
    <!-- <ContributorsDropdown /> -->
    <!-- <Assesment /> -->
    <!-- <Leverage /> -->
    <!-- <Scales /> -->
    <!-- <GrowthScore /> -->
    <!-- <AccordionDropdown /> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";
// import Scales from "@/components/Scales.vue";
// import Leverage from "@/components/Leverage.vue";
// import Assesment from "@/components/Assesment.vue";
// import GrowthScore from "@/components/GrowthScore.vue";
// import TopAdvisors from "@/components/TopAdvisors.vue";
// import Opportunities from "@/components/Opportunities.vue";
// import AccordionDropdown from "@/components/AccordionDropdown.vue";
// import ContributorsDropdown from "@/components/ContributorsDropdown.vue";
// import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Hero,
    // Scales,
    // Leverage,
    // Assesment,
    // GrowthScore,
    // TopAdvisors,
    // Opportunities,
    // ContributorsDropdown,
    // AccordionDropdown,
    // Footer,
  },
  metaInfo: {
    title: "Growth360",
    titleTemplate: null,
  },
  data() {
    return {
      hero: {
        title: "Assessment has moved to Cetera's AdviceWorks",
        content:
          "You can access Growth360° via the app menu on AdviceWorks. Please click on the link below to access AdviceWorks. Once logged in, find the Growth360° icon (see image to the right) in the app menu.",
        hasButton: true,
        buttonText: "GET YOUR GROWTH SCORE",
      },
    };
  },
};
</script>
