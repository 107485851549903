<template>
  <div class="section hero" :class="{ 'small-hero': isResults }">
    <div class="container px-4">
      <div class="row align-items-center">
        <div class="col-12 col-md content order-last order-md-first">
          <h1
            v-scroll-reveal
            v-if="title && hideLogo"
            v-html="title"
            :class="{ 'big-title': bigTitle }"
          />
          <h1
            v-scroll-reveal
            v-else
            class="logo"
            :class="{ 'big-title': bigTitle }"
          >
            <span v-scroll-reveal id="span-logo"
              >Growth<strong>360°</strong></span
            >
            {{ title }}
          </h1>
          <div class="flex-content">
            <p v-scroll-reveal v-if="content">{{ content }}</p>
            <img src="../assets/new-logo.png" />
          </div>

          <div class="">
            <a
              href="https://advisor.adviceworks.net/auth/login"
              v-scroll-reveal
              class="btn btn-primary"
              v-if="hasButton"
            >
              {{ buttonText }}
            </a>
            <a
              href="https://www.cetera.com/privacy-policy"
              target="_blank"
              title="Privacy Policy"
              class="tt-privacy-link"
              >Privacy</a
            >
          </div>
        </div>
        <div class="col-lg-auto col-md align-center image">
          <img
            v-if="image === 'home'"
            v-scroll-reveal
            src="../assets/g360-logo-white.svg"
            class="image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    title: String,
    content: String,
    hasButton: Boolean,
    hideLogo: {
      type: Boolean,
      default: false,
    },
    bigTitle: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "home",
    },
    buttonText: String,
    buttonAction: String,
    isResults: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  background: url(../assets/bg.jpeg) center/cover;

  &.section {
    padding: 8.5vw 0 !important;
    min-height: 30vw !important;
    height: 100vh;
  }

  .container {
    max-width: 1024px;
  }

  &.small-hero {
    padding: 34px 0 !important;
    min-height: 0 !important;
    margin-bottom: 43px;

    .image {
      height: 42vh;
    }

    .content {
      h1 {
        font-size: 40px !important;
      }
    }

    @media screen and (max-width: 800px) {
      .image {
        max-width: 25vw;
        margin: 0 auto 10px;

        img {
          margin-left: -12px;
        }
      }

      .content {
        text-align: center;

        h1 {
          font-size: 30px !important;
        }
      }
    }
  }

  .content {
    text-align: left;

    h1 {
      color: #fff !important;

      &.big-title {
        font-size: 78px !important;
      }
    }

    p {
      color: #fff !important;
      width: 70%;

      @media (max-width: 991.98px) {
        width: 92%;
        text-align: center;
      }
    }
  }

  .image {
    // max-height: 52vh;
    max-height: 300px;
    max-width: calc(40vw - 100px);
    height: 42vh;

    @media screen and (max-width: 800px) {
      max-width: 34vw;
      width: 34vw;
      margin: 0 auto 16px;
      display: inline-block;

      img {
        margin-left: -16px;
      }
    }
  }

  .logo {
    max-width: 500px;
  }

  .flex-content {
    display: flex;
    img {
      object-fit: contain;
    }
  }

  .tt-privacy-link {
    display: block;
    margin-top: 2rem;
    color: white;
    padding: 0.5rem;
    font-family: "FinancierDisplay";
  }
}
</style>
