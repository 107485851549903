<template>
  <div class="container">
    <div class="full-row">
      <div class="content">
        <div class="header-row">
          <h2>Getting More Wallet Share</h2>
          <p>
            To increase client wallet share, we focus on referrals, client
            satisfaction, and connections with your professional network.
          </p>
        </div>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row purple">
      <div class="content">
        <h3>Business Recommendations</h3>
      </div>
      <div class="blank"></div>
    </div>
    <div class="full-row">
      <div class="content main-content">
        <p>In this area we’ll cover:</p>
        <ul>
          <li>
            Referrals and Centers of Influence – It's essential to build and
            maintain a strong referral process to make the most of your current
            client relationships.
          </li>
          <li>
            Finding Outside Assets – Instead of focusing on gaining new clients,
            and increasing your market share, you can focus on finding hidden
            assets and increasing your wallet share from your existing clients.
            In this guide, you’ll learn why it’s essential to provide an
            excellent client experience and build trusting relationships so you
            can work to increase your wallet share and find new outside assets.
          </li>
        </ul>
      </div>
      <div class="blank"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.full-row {
  display: flex;
  flex-direction: row;

  &.purple {
    background-color: #37176d;
    color: white;

    .content {
      padding: 0.5rem;
    }

    h3 {
      color: white !important;
      text-align: left;
      padding-left: 2rem;
      padding-bottom: 0;
      font-weight: 100;
    }
  }

  .content {
    width: 74%;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    .header-row {
      display: flex;
      flex-direction: column;

      h2 {
        margin-left: 1rem !important;
      }

      p {
        padding: 1rem;
        text-align: left;
      }
    }

    &.main-content {
      padding: 2rem;
      padding-top: 3rem;
      text-align: left;

      @media (max-width: 991.98px) {
        padding: 3rem 0.3rem 2rem;
      }

      ul {
        margin: 1rem;
        text-align: left;

        li {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .blank {
    width: 26%;

    @media (max-width: 991.98px) {
      width: 0;
    }
  }
}
</style>
