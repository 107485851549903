<template>
  <modal name="accordion-dropdown" width="80%" height="auto" :scrollable="true">
    <div slot="top-right">
      <button
        class="btn btn-close-modal"
        @click="$modal.hide('accordion-dropdown')"
      >
        X
      </button>
    </div>

    <section>
      <AssessmentWithoutScroll v-if="accordionContent == 'accordion'" />
      <LeverageWithoutScroll v-if="accordionContent == 'accordion'" />
      <ScalesWithoutScroll v-if="accordionContent == 'accordion'" />
      <AcquisitionOverlay v-if="accordionContent == 'acquisition'" />
      <ConversionOverlay v-if="accordionContent == 'conversion'" />
      <WalletOverlay v-if="accordionContent == 'wallet'" />
      <OnboardingOverlay v-if="accordionContent == 'onboarding'" />
    </section>
  </modal>
</template>

<script>
import ScalesWithoutScroll from "@/components/ScalesWithoutScroll.vue";
import LeverageWithoutScroll from "@/components/LeverageWithoutScroll.vue";
import AssessmentWithoutScroll from "@/components/AssessmentWithoutScroll.vue";
import AcquisitionOverlay from "@/components/AcquisitionOverlay.vue";
import ConversionOverlay from "@/components/ConversionOverlay.vue";
import WalletOverlay from "@/components/WalletOverlay.vue";
import OnboardingOverlay from "@/components/OnboardingOverlay.vue";
export default {
  components: {
    ScalesWithoutScroll,
    LeverageWithoutScroll,
    AssessmentWithoutScroll,
    AcquisitionOverlay,
    ConversionOverlay,
    WalletOverlay,
    OnboardingOverlay,
  },
  methods: {},
  computed: {
    accordionContent() {
      return this.$store.state.accordionContent;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
