<template>
  <div>
    <transition appear name="fade">
      <div class="scroll-to-see" v-if="show">
        Scroll down to see more!
        <font-awesome-icon icon="arrow-down" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ScrollToSee",
  data() {
    return {
      show: true,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.show = window.scrollY < 100;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/colors";

.scroll-to-see {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  height: 12vh;
  line-height: 14vh;
  color: $primary-color;
  font-weight: 700;
  background: rgb(225, 225, 225);
  background: linear-gradient(
    0deg,
    rgba(225, 225, 225, 1) 34%,
    rgba(255, 255, 255, 0.7) 100%
  );
  animation: bounce 3s infinite;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

@keyframes bounce {
  0% {
    line-height: 14vh;
  }
  50% {
    line-height: 9vh;
  }
  100% {
    line-height: 14vh;
  }
}
</style>
